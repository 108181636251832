kbd {
  display: inline-block;
  font-family: Consolas, "Lucida Console", monospace;
  font-size: small;
  border: 1px solid #ccc;
  line-height: 1.5;
  border-radius: 5px;
  margin: 1px;
  padding: 0px 4px;
  box-shadow: 1px 1px 1px #777;
  vertical-align: text-bottom;
}

kbd kbd {
  padding: 0px;
  box-shadow: 0px 0px 0px black;
  vertical-align: baseline;
}
